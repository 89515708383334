<template>
<div>

    <CCard>
        <CCardHeader color="primario" text-color="white">
            <i class="fas fa-edit fa-md"></i> <span class="h5"> Gestión de incidencias </span>
        </CCardHeader>
        <CCardBody>
            <b-row>
                <b-col md="12">
                    <validation-observer ref="observer" v-slot="{ handleSubmit }">
                        <b-form @submit.stop.prevent="handleSubmit(registrarNoConformidad)">
                            <b-tabs content-class="mt-3" active-nav-item-class="font-weight-bold text-uppercase text-dark">
                                <b-tab title="Datos generales" active>
                                    <b-row>
                                        <b-col md="2">
                                            <validation-provider name="fecha" :rules="{ required: true }" v-slot="validationContext">
                                                <b-form-group label="Fecha:" class="mb-2">
                                                    <b-form-input type="date" :state="getValidationState(validationContext)" placeholder="Ingrese la fecha" v-model="datosNoConformidad.fecha"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="3">
                                            <validation-provider name="número" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Número:" class="mb-2">
                                                    <b-input-group>
                                                        <b-form-input type="number" :state="getValidationState(validationContext)" placeholder="Ingrese el número" v-model="datosNoConformidad.numero"></b-form-input>
                                                        <b-input-group-append>
                                                            <b-input-group-text>/ 20</b-input-group-text>
                                                        </b-input-group-append>
                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-input-group>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="3">
                                            <validation-provider name="norma" rules="required" v-slot="{errors}">
                                                <b-form-group label="Norma:" class="mb-2">
                                                    <v-select label="descripcion" :reduce="comboNorma =>comboNorma.idNorma" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosNoConformidad.norma , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosNoConformidad.norma" :options="comboNorma" @search:blur="blurNorma">
                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="4">
                                            <validation-provider v-if="datosNoConformidad.norma != 1 && datosNoConformidad.norma" name="tipo" rules="required" v-slot="{errors}">
                                                <b-form-group label="Tipo:" class="mb-2">
                                                    <v-select label="descripcion" :reduce="comboTipo =>comboTipo.idTipo" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosNoConformidad.tipo , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosNoConformidad.tipo" :options="comboTipo" @search:blur="blurTipo">
                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>

                                        <b-col md="4">
                                            <validation-provider name="categoria" rules="required" v-slot="{errors}">
                                                <b-form-group label="Categoría:" class="mb-2">
                                                    <v-select label="nombre" :reduce="comboCategoria =>comboCategoria.idCategoriaIncidente" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosNoConformidad.categoria , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosNoConformidad.categoria" :options="comboCategoria" @search:blur="blurCategoria">
                                                        <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Categorías de las no conformidades'}"> Registra aquí</router-link></span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>

                                        <b-col md="4">
                                            <validation-provider name="proceso" rules="required" v-slot="{errors}">
                                                <b-form-group label="Proceso afectado:" class="mb-2">
                                                    <v-select label="nombre" :reduce="comboProceso =>comboProceso.idProceso" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosNoConformidad.idProceso , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosNoConformidad.idProceso" :options="comboProceso" @search:blur="blurProceso">
                                                        <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Mapa de procesos'}"> Registra aquí</router-link></span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>

                                        <b-col md="4">
                                            <validation-provider name="responsable" :rules="{ required: true }" v-slot="validationContext">
                                                <b-form-group label="Responsable:" class="mb-2">
                                                    <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el responsable" v-model="datosNoConformidad.responsable"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="4">
                                            <validation-provider name="correo del responsable" :rules="{ required: true,email:true }" v-slot="validationContext">
                                                <b-form-group label="Correo del responsable:" class="mb-2">
                                                    <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el correo " v-model="datosNoConformidad.emailResponsable"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>

                                        <b-col md="4">
                                            <validation-provider name="responsable de implantación" :rules="{ required: true }" v-slot="validationContext">
                                                <b-form-group label="Responsable de implantación:" class="mb-2">
                                                    <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el responsable" v-model="datosNoConformidad.responsableImplantacion"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="4">
                                            <validation-provider name="correo del responsable de implantación" :rules="{ required: true ,email:true}" v-slot="validationContext">
                                                <b-form-group label="Correo del responsable de implantación:" class="mb-2">
                                                    <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el correo " v-model="datosNoConformidad.emailResponsableImplantacion"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="2">
                                            <validation-provider name="fecha prevista" :rules="{ required: true }" v-slot="validationContext">
                                                <b-form-group label="Fecha prevista:" class="mb-2">
                                                    <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosNoConformidad.fechaPrevista"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>

                                        <b-col md="2" class="text-center">
                                            <b-form-group label="Resueltas: " class="my-2">
                                                <b-form-radio-group small plain v-model="datosNoConformidad.isResuelta" :options="radioResueltas"></b-form-radio-group>
                                            </b-form-group>
                                        </b-col>
                                        <b-col v-if="datosNoConformidad.isResuelta==2" md="2">
                                            <validation-provider name="fecha de resolución" :rules="{ required: true }" v-slot="validationContext">
                                                <b-form-group label="Fecha de resolución:" class="mb-2">
                                                    <b-form-input type="date" :state="getValidationState(validationContext)" placeholder="Ingrese la fecha de resolución" v-model="datosNoConformidad.fechaResolucion"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col v-else md="2"></b-col>

                                        <b-col md="6">
                                            <validation-provider name="descripción" v-slot="validationContext">
                                                <b-form-group label="Descripción:" class="mb-2">
                                                    <b-form-textarea v-model="datosNoConformidad.descripcion" placeholder="Ingrese la descripción" rows="3" max-rows="6" :state="getValidationState(validationContext)"></b-form-textarea>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="6">
                                            <validation-provider name="actuaciones inmediatas" v-slot="validationContext">
                                                <b-form-group label="Actuaciones inmediatas:" class="mb-2">
                                                    <b-form-textarea v-model="datosNoConformidad.actuacionInmediata" placeholder="Ingrese las actuaciones inmediatas" rows="3" max-rows="6" :state="getValidationState(validationContext)"></b-form-textarea>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="6">
                                            <validation-provider name="posibles causas" v-slot="validationContext">
                                                <b-form-group label="Posibles causas:" class="mb-2">
                                                    <b-form-textarea v-model="datosNoConformidad.posibleCausa" placeholder="Ingrese las posibles causas" rows="3" max-rows="6" :state="getValidationState(validationContext)"></b-form-textarea>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="6">
                                            <validation-provider name="acciones correctivas" v-slot="validationContext">
                                                <b-form-group label="Acciones Correctivas / Preventivas:" class="mb-2">
                                                    <b-form-textarea v-model="datosNoConformidad.accionCorrectiva" placeholder="Ingrese las acciones correctivas y preventivas" rows="3" max-rows="6" :state="getValidationState(validationContext)"></b-form-textarea>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="6">
                                            <validation-provider name="observaciones" v-slot="validationContext">
                                                <b-form-group label="Observaciones:" class="mb-2">
                                                    <b-form-textarea v-model="datosNoConformidad.observacion" placeholder="Ingrese las observaciones" rows="3" max-rows="6" :state="getValidationState(validationContext)"></b-form-textarea>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>

                                    </b-row>
                                </b-tab>
                                <b-tab title="Evidencias">
                                    <b-row>
                                        <b-col md="12">
                                            <CButton size="sm" color="dark" class="float-right" @click="agregarEvidencias()">
                                                <i class="fas fa-plus fa-sm"></i> Agregar
                                            </CButton>
                                        </b-col>
                                        <b-col md="12 my-2">
                                            <b-table :items="listaNoConformidadEvidencias.filter(x => x.estado == 2)" :fields="camposEvidencias" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                <template v-slot:cell(nombre)="data">
                                                    <validation-provider :name="'nombre'+data.index" :rules="{ required: true }" v-slot="validationContext">
                                                        <b-form-input placeholder="Ingrese nombre" type="text" :state="getValidationState(validationContext)" v-model="data.item.nombre"></b-form-input>
                                                    </validation-provider>
                                                </template>
                                                <template v-slot:cell(documento)="row">
                                                    <b-input-group v-if="row.item.urlArchivo !== null && row.item.urlArchivo != ''">
                                                        <b-input-group-prepend>
                                                            <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(row.item.urlArchivo)" v-c-tooltip="'Descargar'">
                                                                <i class="fas fa-download fa-xs"></i>
                                                            </b-button>
                                                        </b-input-group-prepend>
                                                        <b-form-file class="text-left" :ref="'filex'+row.index" v-model="row.item.documento" :placeholder="row.item.nombreArchivo" drop-placeholder="Arrastre el documento aquí.." :accept="accept.toString()" browse-text="Subir" v-on:change="handlexFileUpload($event,row.index)"></b-form-file>
                                                    </b-input-group>
                                                    <b-form-file class="text-left" v-else :ref="'filex'+row.index" v-model="row.item.documento" v-on:change="handlexFileUpload($event,row.index)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Arrastre el documento aquí.." browse-text="Subir" :accept="accept.toString()"></b-form-file>
                                                </template>

                                                <template v-slot:cell(opciones)="param">
                                                    <b-button v-if="param" size="sm" variant="danger" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarEvidencia(param)">
                                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                    </b-button>
                                                </template>
                                            </b-table>
                                        </b-col>
                                    </b-row>
                                </b-tab>
                                <b-tab title="No Conformidades vinculadas">
                                    <b-row>
                                        <b-col md="12">
                                            <CButton size="sm" color="dark" class="float-right" @click="agregarNoConformidades()">
                                                <i class="fas fa-plus fa-sm"></i> Agregar
                                            </CButton>
                                        </b-col>
                                        <b-col md="12 my-2">
                                            <b-table :items="listaNoConformidadNC.filter(x => x.estado == 2)" :fields="camposNoConformidades" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                <template v-slot:cell(noConformidad)="data">
                                                    <validation-provider :name="'no_conformidad'" rules="required" v-slot="{errors}">
                                                        <v-select label="noConformidad" :reduce="comboNoConformidades =>comboNoConformidades.idNoConformidad" placeholder="Seleccione una opción" :class=" {'style-valid-select': data.item.idNoConformidadVinculada , 'style-invalid-select is-invalid': !!errors.length}" v-model="data.item.idNoConformidadVinculada" :options="comboNoConformidades" @search:blur="blurNoConformidad">
                                                            <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                        </v-select>
                                                    </validation-provider>
                                                </template>
                                                <template v-slot:cell(comentario)="data">
                                                    <validation-provider :name="'comentario'+data.index" :rules="{ required: true }" v-slot="validationContext">
                                                        <b-form-textarea rows="2" max-rows="6" placeholder="Ingrese comentario" :state="getValidationState(validationContext)" v-model="data.item.comentario"></b-form-textarea>
                                                    </validation-provider>
                                                </template>

                                                <template v-slot:cell(opciones)="param">
                                                    <b-button v-if="param" size="sm" variant="danger" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarNoConformidad(param)">
                                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                    </b-button>
                                                </template>
                                            </b-table>
                                        </b-col>
                                    </b-row>
                                </b-tab>

                                <b-col md="12" class="text-center my-2">
                                    <b-button :disabled="disabled" variant="dark" class=" mr-2" :to="{name: 'No Conformidades'}">
                                        <i class="fas fa-arrow-left"></i> Volver
                                    </b-button>

                                    <b-button v-if="$route.params.id  && checkPermissions('011-INC-NOC','u')==1" :disabled="disabled" variant="success" type="submit">
                                        <i class="fas fa-save"></i> Guardar
                                    </b-button>
                                    <b-button v-if="!$route.params.id  && checkPermissions('011-INC-NOC','c')==1" :disabled="disabled" variant="success" type="submit">
                                        <i class="fas fa-save"></i> Guardar
                                    </b-button>
                                </b-col>
                            </b-tabs>

                        </b-form>

                    </validation-observer>
                </b-col>
            </b-row>
        </CCardBody>
    </CCard>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    data() {
        return {
            disabled: false,
            datosNoConformidad: {
                idCliente: null,
                idNoConformidad: '',
                fecha: moment().format('YYYY-MM-DD'),
                numero: '',
                norma: null,
                tipo: null,
                categoria: null,
                idProceso: null,
                responsable: '',
                emailResponsable: '',
                descripcion: '',
                actuacionInmediata: '',
                posibleCausa: '',
                accionCorrectiva: '',
                responsableImplantacion: '',
                emailResponsableImplantacion: '',
                fechaPrevista: moment().format('YYYY-MM-DD'),
                isResuelta: 1,
                fechaResolucion: '',
                observacion: ''
            },

            comboNorma: [{
                idNorma: 1,
                descripcion: "Calidad (9001)"
            }, {
                idNorma: 2,
                descripcion: "MedioAmbiente (14001)"
            }, {
                idNorma: 3,
                descripcion: "Seguridad Laboral (45001)"
            }, {
                idNorma: 4,
                descripcion: "AntiSoborno (37001)"
            }],
            comboTipo: [{
                idTipo: 1,
                descripcion: "Accidente"
            }, {
                idTipo: 2,
                descripcion: "Incidente"
            }],

            comboCategoria: [],
            comboProceso: [],
            radioResueltas: [{
                    value: "2",
                    text: "Si"
                },
                {
                    value: "1",
                    text: "No"
                },
            ],

            listaNoConformidadEvidencias: [],

            camposEvidencias: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: 'nombre',
                    label: 'Nombre',
                    class: 'text-center'
                },
                {
                    key: 'documento',
                    label: 'Fichero',
                    class: 'text-center'
                }, {
                    key: 'opciones',
                    label: '',
                    class: 'text-center'
                }
            ],
            camposNoConformidades: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: 'noConformidad',
                    label: 'No Conformidad',
                    class: 'text-center'
                },
                {
                    key: 'comentario',
                    label: 'Comentario',
                    class: 'text-center'
                }, {
                    key: 'opciones',
                    label: '',
                    class: 'text-center'
                }
            ],
            comboNoConformidades: [],
            listaNoConformidadNC: [],
               accept: [
                '.vsd', '.vsdx', '.xlsx', '.xls', '.csv',
                '.pdf', '.doc', '.docx', '.ppt', '.pptx',
                '.png', '.jpg', '.jpeg', '.pdf', '.docx',
                'application/msword',
                'application/vnd.ms-excel'
            ],
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },

        registrarNoConformidad() {
            let me = this;
            me.disabled = true;

            const formData = new FormData();

            let data = 0;

            for (const i in me.listaNoConformidadEvidencias) {
                if (me.listaNoConformidadEvidencias[i].idNoConformidadEvidencia == 0 && me.listaNoConformidadEvidencias[i].documento != null) {
                    data = data - 1;
                    formData.append("filex" + data, me.listaNoConformidadEvidencias[i].documento);
                } else {
                    formData.append("filex" + me.listaNoConformidadEvidencias[i].idNoConformidadEvidencia, me.listaNoConformidadEvidencias[i].documento);
                };
            }
            formData.append("listaNoConformidadEvidencias", JSON.stringify(me.listaNoConformidadEvidencias));
            formData.append("listaNoConformidadNC", JSON.stringify(me.listaNoConformidadNC));

            formData.append("folder", 'incidentes/gestion-de-no-conformidades');

            /*---Datos del objeto--*/
            formData.append("idCliente", me.datosNoConformidad.idCliente);

            formData.append("idNoConformidad", me.datosNoConformidad.idNoConformidad);
            formData.append("fecha", me.datosNoConformidad.fecha);
            formData.append("numero", me.datosNoConformidad.numero);
            formData.append("norma", me.datosNoConformidad.norma);
            formData.append("tipo", me.datosNoConformidad.tipo);
            formData.append("categoria", me.datosNoConformidad.categoria);
            formData.append("idProceso", me.datosNoConformidad.idProceso);
            formData.append("responsable", me.datosNoConformidad.responsable);
            formData.append("emailResponsable", me.datosNoConformidad.emailResponsable);
            formData.append("descripcion", me.datosNoConformidad.descripcion);
            formData.append("actuacionInmediata", me.datosNoConformidad.actuacionInmediata);
            formData.append("posibleCausa", me.datosNoConformidad.posibleCausa);
            formData.append("accionCorrectiva", me.datosNoConformidad.accionCorrectiva);
            formData.append("responsableImplantacion", me.datosNoConformidad.responsableImplantacion);
            formData.append("emailResponsableImplantacion", me.datosNoConformidad.emailResponsableImplantacion);
            formData.append("fechaPrevista", me.datosNoConformidad.fechaPrevista);
            formData.append("isResuelta", me.datosNoConformidad.isResuelta);
            formData.append("fechaResolucion", me.datosNoConformidad.fechaResolucion);
            formData.append("observacion", me.datosNoConformidad.observacion);

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-no-conformidad",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.disabled = false;
                    me.$router.push({
                        name: 'No Conformidades'
                    });
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        handlexFileUpload(e, i) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(vsd|vsdx|xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg|pdf|docx)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs[`filex${i}`].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs[`filex${i}`].reset();
                    return;
                }
            }
        },
        agregarEvidencias() {
            this.listaNoConformidadEvidencias.push({
                idNoConformidadEvidencia: 0,
                nombre: '',
                documento: null,
                nombreArchivo: '',
                urlArchivo: '',
                estado: 2
            })
        },
        agregarNoConformidades() {
            this.listaNoConformidadNC.push({
                idNoConformidadNC: 0,
                idNoConformidadVinculada: '',
                comentario: '',
                estado: 2
            })
        },

        obtenerNoConformidad() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-no-conformidad", {
                        params: {
                            idCliente: me.datosNoConformidad.idCliente,
                            idNoConformidad: me.$route.params.id
                        },
                    }
                )
                .then(function (response) {

                    me.datosNoConformidad.idCliente = response.data[0].idCliente;
                    me.datosNoConformidad.idNoConformidad = response.data[0].idNoConformidad;
                    me.datosNoConformidad.fecha = response.data[0].fecha;
                    me.datosNoConformidad.numero = response.data[0].numero;
                    me.datosNoConformidad.norma = response.data[0].norma;
                    me.datosNoConformidad.tipo = parseInt(response.data[0].tipo);
                    me.datosNoConformidad.categoria = response.data[0].idCategoriaIncidente;
                    me.datosNoConformidad.idProceso = response.data[0].idProceso;
                    me.datosNoConformidad.responsable = response.data[0].responsable;
                    me.datosNoConformidad.emailResponsable = response.data[0].emailResponsable;
                    me.datosNoConformidad.descripcion = response.data[0].descripcion;
                    me.datosNoConformidad.actuacionInmediata = response.data[0].actuacionInmediata;
                    me.datosNoConformidad.posibleCausa = response.data[0].posibleCausa;
                    me.datosNoConformidad.accionCorrectiva = response.data[0].accionCorrectiva;
                    me.datosNoConformidad.responsableImplantacion = response.data[0].responsableImplantacion;
                    me.datosNoConformidad.emailResponsableImplantacion = response.data[0].emailResponsableImplantacion;
                    me.datosNoConformidad.fechaPrevista = response.data[0].fechaPrevista;
                    me.datosNoConformidad.isResuelta = response.data[0].isResuelta;
                    me.datosNoConformidad.fechaResolucion = response.data[0].fechaResolucion;
                    me.datosNoConformidad.observacion = response.data[0].observacion;

                    me.obtenerNoConformidadEvidencias();
                    me.obtenerNoConformidadesNC();
                    me.listarNoConformidades();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                    me.$router.push({
                        name: 'No Conformidades'
                    });
                });
        },
        obtenerNoConformidadEvidencias() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-no-conformidad-evidencias", {
                        params: {
                            idCliente: me.datosNoConformidad.idCliente,
                            idNoConformidad: me.datosNoConformidad.idNoConformidad
                        },
                    }
                )
                .then(function (response) {
                    me.listaNoConformidadEvidencias = response.data
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                    me.$router.push({
                        name: 'No Conformidades'
                    });
                });
        },
        listarNoConformidades() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-no-conformidades", {
                    params: {
                        idCliente: me.datosNoConformidad.idCliente,
                    }
                })
                .then(function (response) {
                    if(me.$route.params.id){
                        me.comboNoConformidades = response.data.filter(x => x.idNoConformidad!=me.datosNoConformidad.idNoConformidad);
                    }  else{
                        me.comboNoConformidades = response.data;
                    }
                    
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.$router.push({
                        name: 'No Conformidades'
                    });
                });
        },
        obtenerNoConformidadesNC() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-no-conformidades-nc", {
                        params: {
                            idCliente: me.datosNoConformidad.idCliente,
                            idNoConformidad: me.datosNoConformidad.idNoConformidad
                        },
                    }
                )
                .then(function (response) {
                    me.listaNoConformidadNC = response.data
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                    me.$router.push({
                        name: 'No Conformidades'
                    });
                });
        },
        blurNorma() {
            this.computedForm.refs.norma.validate();
        },
        blurNoConformidad() {
            this.computedForm.refs.no_conformidad.validate();
        },
        blurTipo() {
            this.computedForm.refs.tipo.validate();
        },
        blurCategoria() {
            this.computedForm.refs.categoria.validate();
        },
        blurProceso() {
            this.computedForm.refs.proceso.validate();
        },
        registrarEvidencia() {
            let me = this;
            me.listaNoConformidadEvidencias.push({
                nombre: me.datosEvidencia.nombre
            })
        },
        listarProcesos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-procesos", {
                        params: {
                            idCliente: me.datosNoConformidad.idCliente
                        },
                    }
                )
                .then(function (response) {

                    me.comboProceso = response.data
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarCategorias() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-categorias-incidentes", {
                        params: {
                            idCliente: me.datosNoConformidad.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboCategoria = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        eliminarEvidencia(param) {
            let me = this;
            if (me.$route.params.id) {
                let lista = me.listaNoConformidadEvidencias.filter(x => x.estado == 2);
                if (lista[param.index].idNoConformidadEvidencia != 0) {
                    lista[param.index].estado = 1
                } else if (lista[param.index].idNoConformidadEvidencia == 0) {
                    lista[param.index].estado = 0
                    for (let e in me.listaNoConformidadEvidencias) {
                        if (me.listaNoConformidadEvidencias[e].estado == 0) {
                            me.listaNoConformidadEvidencias.splice(e, 1);
                        }
                    }
                }
            } else {
                me.listaNoConformidadEvidencias.splice(param.index, 1)
            }
        },
        eliminarNoConformidad(param) {
            let me = this;
            if (me.$route.params.id) {
                let lista = me.listaNoConformidadNC.filter(x => x.estado == 2);
                if (lista[param.index].idNoConformidadNC != 0) {
                    lista[param.index].estado = 1
                } else if (lista[param.index].idNoConformidadNC == 0) {
                    lista[param.index].estado = 0
                    for (let e in me.listaNoConformidadNC) {
                        if (me.listaNoConformidadNC[e].estado == 0) {
                            me.listaNoConformidadNC.splice(e, 1);
                        }
                    }
                }
            } else {
                me.listaNoConformidadNC.splice(param.index, 1)
            }
        },
        resetModalRegistrarEvidencia() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
            this.datosEvidencia.nombre = '';
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosNoConformidad.idCliente = user.uidClient;
            this.listarProcesos();
            this.listarCategorias();

            if (this.$route.params.id) {
                this.obtenerNoConformidad();
            }else{
                this.listarNoConformidades();
            }
        }
    }

}
</script>
